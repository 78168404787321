import React from 'react'
import { makeDecision } from '../Utils/DeciderUtils'

const DecideButton = ({options, decision, setDecision, notifyUser}) => {
    /**
     * Allow the user to clear the decision that was made, this allows them to go on to make a new decision with the
     * application
     */
    const clearDecision = () => { 
        if(decision) {
            setDecision(null);
            notifyUser("SUCCESS", "Whoosh! Decision cleared.");
        }
    }

    /**
     * Allow the user to make a decision. This accepts an array of options that the user wants to make a decision on, as well
     * as the function to set the decision once it's done. This will do this randomly. The more decisions, the more random this
     * will be.
     * 
     * @returns the decision that has been made, can be ignored as this is set into the state of the application.
     */
    const makeDecision = () => {
        if (options && options.length > 0) {
            const decision = Math.floor((Math.random() * options.length));
            if (setDecision) setDecision(options[decision]);
            return options[decision];
        } else {
            return;
        }
    }

    return (
        <div className="DecideButton-container">
            <div className="column half left">
                <button 
                    onClick={() => makeDecision()} 
                    data-testid="decidebutton"
                    className="DecideButton">
                    Decide!
                </button>
            </div>
            <div className="column half right">
                <button
                    onClick={() => clearDecision()}
                    data-testid="clearbutton"
                    className="ClearButton">
                    Clear
                </button>
            </div>
        </div>
    )
}

export default DecideButton;