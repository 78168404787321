import { useState, useRef } from "react";
import SplashScreen from "./Components/SplashScreen";
import Decision from "./Components/Decision";
import DecideButton from "./Components/DecideButton"
import useWindowDimensions from "./Hooks/useWindowDimensions"
import Confetti from 'react-confetti'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { width, height } = useWindowDimensions();

  // Status of the application determines actions
  const [status, setStatus] = useState("LOADED");
  const [decision, setDecision] = useState("Germany");
  const [options, setOptions] = useState(["Spain", "Italy", "France"]);

  // Allow notifications to be displayed to the user
  const toastId = useRef(null);
  const notifyUser = (type, message) => {
    // Ensure toast only displays once
    if (!toast.isActive(toastId.current)) {
      switch(type) {
        case "INFO":
          toastId.current = toast.info(message);
          break;
        case "ERROR":
          toastId.current = toast.error(message);
          break;
        case "WARNING":
          toastId.current = toast.warning(message);
          break;
        case "SUCCESS":
          toastId.current = toast.success(message);
          break;
        default:
          toastId.current = toast(message);
          break;
      }
    }
  }

  return (
    <div className="App">
      {status === "LOADING" && 
        <SplashScreen setStatus={setStatus} /> 
      }

      {status === "LOADED" && 
        <div className="row">
          <ToastContainer limit={3} position="bottom-center" newestOnTop autoClose="2500" closeOnClick draggable theme="dark"/>
          {decision && <Confetti width={width} height={height} /> }
          <div className="column quarter left">
            <Decision decision={decision} />
            <DecideButton options={options} decision={decision} setDecision={setDecision} notifyUser={notifyUser}/>
          </div>
          <div className="column three-quarter right">
            Col 2
          </div>
        </div>
      }
    </div>
  );
}

export default App;
