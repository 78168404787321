/**
 * @author Karl Jones
 * @email karljones@live.ie
 * @create date 2022-06-29 12:11:12
 * @modify date 2022-08-24 12:22:32
 * @desc Display a splash screen when the application is loading
 */
import React, { useState, useEffect } from "react";
import { useConfig } from "../Hooks/useConfig"

const SplashScreen = ({ setStatus }) => {
  const { splashScreenMessages } = useConfig(); 
  const [title, setTitle] = useState(splashScreenMessages[0]);

  useEffect(() => {
    var sequence = 0;
    const interval = setInterval(() => {
      setTitle(splashScreenMessages[sequence]);
      if (sequence === splashScreenMessages.length) {
        setStatus("LOADED")
        clearInterval(interval);
      }
      sequence++;
    }, 750);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="SplashScreen--container">
      <h1 className="SplashScreen--title">{title}</h1>
    </div>
  );
};

export default SplashScreen;
