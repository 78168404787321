/**
 * @author Karl Jones
 * @email karljones@live.ie
 * @create date 2022-06-29 22:19:11
 * @modify date 2022-08-22 09:11:14
 * @desc Display the decision to the user
 */

import React from 'react'

const Decision = ({decision}) => {
    return (
        <div className="Decision--container">
            <h1 className="Decision--title">Decider</h1>
            <p className="Decision--display">{decision}</p>
        </div>
    )
}

export default Decision;